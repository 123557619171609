<template>
  <div v-if="project" class="v-stack h-stretch gap-3">
    <a class="heading-title-1">Crew Workhours</a>
    <div class="table">
      <div class="table-header workhours-row">
        <div>Date</div>
        <div>Name</div>
        <div>Role</div>
        <div>Hours</div>
        <div>Amount</div>
        <div>Description</div>
      </div>
      <div
        v-for="workhour in project.workhours"
        :key="workhour._id"
        class="table-row workhours-row"
      >
        <template v-if="workhour.workday">
          <div>{{ formatDate(workhour.workday.day, "DD.MM.YYYY") }}</div>
          <div>
            {{ workhour.workday.user.firstName }}
            {{ workhour.workday.user.lastName }}
          </div>
          <div>{{ workhour.activity }}</div>
          <div>{{ workhour.hours }}</div>
          <div>
            {{
              format.currency(Math.round(workhour.hours * workhour.pay.amount))
            }}
            Kč
          </div>
          <div>{{ workhour.comment }}</div>
        </template>
      </div>
      <div v-if="project.workhours.length == 0" class="table-row">
        <div>No Workhours</div>
      </div>
    </div>

    <a class="heading-title-1">Freelancers</a>
    <div class="table">
      <div class="table-header external-row">
        <div>Date</div>
        <div>Roles</div>
        <div>Name</div>
        <div>Cost</div>
      </div>
      <div
        v-for="staff in staff"
        :key="staff._id"
        class="table-row external-row"
      >
        <div>Not Implemented</div>
        <div>{{ staff.roles.join(", ") }}</div>
        <div>{{ staff.user.firstName }} {{ staff.user.lastName }}</div>
        <div>{{ format.currency(staff.cost) }} Kč</div>
      </div>
      <div v-if="staff.length == 0" class="table-row">
        <div>No Hired</div>
      </div>
    </div>

    <a class="heading-title-1">Additional Expenses</a>
    <div class="table">
      <div class="table-header expenses-row">
        <div>Title</div>
        <div>Cost</div>
        <div>Description</div>
      </div>
      <div
        v-for="expense in project.expenses"
        :key="expense._id"
        class="table-row expenses-row"
      >
        <div>{{ expense.name }}</div>
        <div>{{ format.currency(expense.amount) }} Kč</div>
        <div>{{ expense.description }}</div>
      </div>
      <div v-if="project.expenses.length == 0" class="table-row">
        <div>No expenses</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import utils from "@/utils.js";

export default {
  props: ["id"],
  data() {
    return {
      project: null,
      format: utils.format,
    };
  },
  computed: {
    staff() {
      let staff = [];

      staff = staff.concat(this.project.preproductionStaff);
      staff = staff.concat(this.project.postproductionStaff);

      for (const day of this.project.recordingDays) {
        staff = staff.concat(day.staff);
      }

      return staff.filter((staff) => staff.cost > 0);
    },
  },
  methods: {
    ...mapActions(["getProject"]),
    formatDate(dateString, format) {
      const date = moment(dateString);
      return date.format(format);
    },
    refresh() {
      this.getProject(this.id)
        .then((project) => {
          this.project = project;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>

<style lang="scss" scoped>
.workhours-row {
  grid-auto-columns: 1fr 1fr 1fr 1fr !important;
  gap: 10px;
}

.external-row {
  grid-auto-columns: 1fr 1fr 1fr !important;
  gap: 10px;
}

.expenses-row {
  grid-auto-columns: 1fr 1fr 1fr !important;
  gap: 10px;
}
</style>
